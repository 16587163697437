import { Rate } from 'antd';
import { POST_REVIEW, UPDATE_REVIEW } from 'lib/mutations';
import { GET_COURSE_REVIEWS_BY_USER } from 'lib/queries';
import Image from 'next/image';
import Link from 'next/link';
import commentIcon from 'public/images/comment-icon.svg';
import { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import styled from 'styled-components';
import toastr from 'toastr';
import main from 'utils/mutationWrapper';

import { InvertedRoundButton, RoundButton } from '@/design-system/buttons';

const StyledComment = styled.div`
  width: 100%;
  min-height: 216px;
  background: #262f40;
  padding: 34px 100px;
  margin: 5px 0;
  @media (min-width: 760px) and (max-width: 1025px) {
    padding: 34px 35px;
  }
  @media (max-width: 759px) {
    padding: 34px 16px;
  }
  .comment-header {
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 37px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    color: ${(props) => props.theme.primaryText};
    opacity: 0.7;
    margin-bottom: 5px;
  }
  .comment-divider {
    height: 2px;
    background-color: ${(props) => props.theme.dividerBackground};
  }
  .comment-guest-notice {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    color: ${(props) => props.theme.primaryText};
    opacity: 0.7;
    margin: 20px 0;
  }
  .comment-guest-actions {
    max-width: 35%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.04em;
    color: ${(props) => props.theme.primaryText};
    @media (min-width: 760px) and (max-width: 1025px) {
      max-width: 50%;
    }
    @media (max-width: 759px) {
      max-width: 90%;
    }
  }
  .comment-guest-actions a {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.04em;
    color: ${(props) => props.theme.primaryText};
  }
  .review {
    background: ${(props) => props.theme.testimonialBg};
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 24px;
    display: grid;
    grid-template-columns: 150px 1fr;
    grid-column-gap: 20px;
  }
  .review-name {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    color: ${(props) => props.theme.primaryText};
  }
  .review-msg {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    padding: 25px;
    color: ${(props) => props.theme.primaryText};
  }
  .rating-count-co {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 10px;
  }
  .create-review {
    background: ${(props) => props.theme.testimonialBg};
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 40px;
    margin-bottom: 40px;
    .create-review-action {
      display: flex;
      justify-content: center;
    }
  }
  .create-review-title {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 22px;
    align-items: center;
    text-align: center;
    color: ${(props) => props.theme.primaryText};
  }
  .create-review-rate {
    display: grid;
    place-items: center;
    justify-content: center;
    margin: 10px 0;
  }
  .rate-message-input {
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    border-radius: 4px;
    background: transparent;
    width: 100%;
    margin: 10px 0;
  }
  .advert {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;
    div {
      margin: 0 10px;
    }
    a.login-link {
      color: ${(props) => props.theme.primaryText};
      font-weight: 500;
      font-size: 18px;
    }
  }
`;

const Comment = ({ auth, activeSubscription, reviews, lesson, hasAccess, paidCourse, isFree }) => {
  const desc = ['Terrible', 'Not good', 'Just okay', 'Quite good', 'Exceeded expectations'];
  const [cookies] = useCookies([]);

  const [courseReviews, setCourseReviews] = useState([]);
  const [myReview, setMyReview] = useState(null);

  useEffect(() => {
    setCourseReviews(reviews);
  }, [reviews]);

  useEffect(() => {
    fetchUserReviews();
  }, [fetchUserReviews]);

  const [rate, setRate] = useState(null);
  const [rateMesssage, setRateMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const updateRate = (value) => setRate(value);

  const updateRateMessage = (ev) => setRateMessage(ev.target.value);

  const handleShowReviewEdit = () => {
    setRate(myReview?.rating);
    setRateMessage(myReview?.description);
    setIsEditing(true);
  };

  const handleCreateReview = async () => {
    if (!rate) {
      toastr.error('Please, rate course');
      return;
    }
    let formData = {
      input: {
        description: rateMesssage,
        rating: rate,
        courseId: lesson?.courseId
      }
    };

    setIsSubmitting(true);
    try {
      const newReview = await main(POST_REVIEW, formData, cookies);
      toastr.success('Your review was posted successfully');
      setRateMessage('');
      setRate(null);
      setIsSubmitting(false);
      setCourseReviews((prevState) => {
        return [...prevState, newReview.createReview];
      });
    } catch (error) {
      setIsSubmitting(false);
      toastr.error('Error posting review');
    }
  };

  const handleUpdateReview = async () => {
    if (!rate) {
      toastr.error('Please, rate course');
      return;
    }
    const formData = {
      input: {
        description: rateMesssage,
        rating: rate,
        courseId: lesson?.courseId
      },
      id: myReview?._id
    };

    setIsSubmitting(true);
    try {
      const { updateReview: updatedReview } = await main(UPDATE_REVIEW, formData, cookies);
      toastr.success('Your review was updated successfully');
      setRateMessage('');
      setRate(null);
      setIsSubmitting(false);
      setIsEditing(false);
      setMyReview(updatedReview);
    } catch (error) {
      setIsSubmitting(false);
      toastr.error('Error updating review');
    }
  };

  const fetchUserReviews = useCallback(async () => {
    const { getCourseReviewRatingbyUser: courseReviewsByUser } = await main(
      GET_COURSE_REVIEWS_BY_USER,
      {
        courseId: lesson.courseId
      },
      cookies
    );
    setMyReview(courseReviewsByUser);
  }, [lesson.courseId]);

  useEffect(() => {
    if (myReview) {
      const reviewsWithoutMe = courseReviews?.filter((review) => review._id !== myReview._id);
      setCourseReviews(reviewsWithoutMe);
    }
  }, [myReview]);

  return (
    <StyledComment>
      <div className="comment-header">
        <Image src={commentIcon} alt="Comment icon" /> Comment
      </div>
      <div className="comment-divider" />
      <div className="comment-guest-notice">
        {!activeSubscription && !paidCourse && (
          <div>You have to be a subscriber or buy the course to rate or review course </div>
        )}
      </div>
      <div className="advert">
        {((isFree && !activeSubscription) || (!isFree && !hasAccess)) && (
          <>
            <Link href={`/pricing`} passHref>
              <InvertedRoundButton
                medium
                style={{ background: '#fff', color: '#AC005D', textTransform: 'capitalize' }}>
                Subscribe {'   '}
              </InvertedRoundButton>
            </Link>
          </>
        )}

        {!auth && (
          <>
            <div>or</div>
            <Link href="/login" passHref>
              <a className="login-link">Login</a>
            </Link>
          </>
        )}
      </div>
      {(activeSubscription || paidCourse) && (!myReview || isEditing) && (
        <div className="create-review">
          <div className="create-review-title">
            {!isEditing ? 'How will you rate this course?' : 'Update course rating'}
          </div>
          <div className="create-review-rate">
            <Rate tooltips={desc} onChange={updateRate} value={rate} />
            {rate ? <span className="ant-rate-text">{desc[rate - 1]}</span> : ''}
          </div>
          <textarea
            value={rateMesssage}
            onChange={updateRateMessage}
            className="rate-message-input"
            placeholder="Leave a public review"
          />
          <div className="create-review-action">
            <RoundButton
              as="button"
              medium
              onClick={isEditing ? handleUpdateReview : handleCreateReview}
              disabled={isSubmitting}>
              {!isEditing ? 'Submit ' : 'Update '} Review
            </RoundButton>
          </div>
        </div>
      )}
      {myReview && (
        <div className="review" key={myReview._id}>
          <div className="review-name">
            {myReview.user?.firstName} {myReview.user?.lastName}
          </div>
          <div className="review-msg">
            <div className="rating-count-co">
              Rating: <Rate disabled defaultValue={myReview.rating} />{' '}
            </div>
            <div className="review-note">{myReview.description}</div>
            <InvertedRoundButton
              as="button"
              small
              onClick={handleShowReviewEdit}
              disabled={isSubmitting}>
              Edit Review
            </InvertedRoundButton>
          </div>
        </div>
      )}
      {courseReviews?.map((courseReview) => (
        <div className="review" key={courseReview._id}>
          <div className="review-name">
            {courseReview.user?.firstName} {courseReview.user?.lastName}
          </div>
          <div className="review-msg">
            <div className="rating-count-co">
              Rating: <Rate disabled defaultValue={courseReview.rating} />{' '}
            </div>
            <div className="review-note">{courseReview.description}</div>
          </div>
        </div>
      ))}
    </StyledComment>
  );
};

export default Comment;
