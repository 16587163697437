import { FeedbackFish } from '@feedback-fish/react';
import Image from 'next/image';
import Link from 'next/link';
import emptyIcon from 'public/images/empty.png';
import styled from 'styled-components';

import { RoundButton } from '@/design-system/buttons';

const StyledNotFound = styled.div`
  margin: 78px 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  @media (min-width: 760px) and (max-width: 1025px) {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: initial;
  }
  @media (min-width: 320px) and (max-width: 759px) {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: initial;
  }
  .empty-img,
  .empty-img img {
    height: 257px !important;
    width: auto !important;
    min-width: initial !important;
    @media (min-width: 760px) and (max-width: 1025px) {
      justify-self: center;
    }
    @media (min-width: 320px) and (max-width: 759px) {
      justify-self: center;
      height: 200px !important;
    }
  }
  .empty-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .empty-title {
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 40px;
    letter-spacing: 0.03em;
    color: ${({ theme }) => theme.primaryText};

    @media (min-width: 760px) and (max-width: 1025px) {
      text-align: center;
    }
    @media (min-width: 320px) and (max-width: 759px) {
      text-align: center;
      line-height: 35px;
    }
  }
  .empty-desc {
    margin-top: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: ${({ theme }) => theme.fadedGrey};

    @media (min-width: 760px) and (max-width: 1025px) {
      text-align: center;
    }
    @media (min-width: 320px) and (max-width: 759px) {
      text-align: center;
    }
  }
  a {
    @media (min-width: 760px) and (max-width: 1025px) {
      align-self: center;
    }
    @media (min-width: 320px) and (max-width: 759px) {
      align-self: center;
      margin: 50px 0 20px 0;
    }
  }
  .feedback-button {
    background: transparent;
    width: 200px;
    color: #ffffff;
  }
  .actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const NotFound = ({ title, desc, showImage = true }) => (
  <StyledNotFound>
    {showImage && (
      <Image src={emptyIcon} placeholder="blur" alt="Empty Icon" className="empty-img" />
    )}
    <div className="empty-content">
      <div className="empty-text">
        <div className="empty-title">{title ? <div>{title}</div> : <div>No item found</div>}</div>
        <div className="empty-desc">
          {desc ? (
            <div>{desc}</div>
          ) : (
            <div>
              We are constantly updating our contents.
              <br /> Need a particular course, lesson or instructor? Send us a mail{' '}
              <a href="mailto:support@brytahub.com">support@brytahub.com</a>
            </div>
          )}
        </div>
      </div>
      <div className="actions">
        <Link href="/learn" passHref>
          <RoundButton medium>Discover Courses</RoundButton>
        </Link>
        {process.env.NEXT_PUBLIC_FEEDBACK_FISH_PROJECT_ID && (
          <FeedbackFish projectId={process.env.NEXT_PUBLIC_FEEDBACK_FISH_PROJECT_ID}>
            <button className="feedback-button">Send us feedback</button>
          </FeedbackFish>
        )}
      </div>
    </div>
  </StyledNotFound>
);

export default NotFound;
