import { DeleteFilled } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { MyTextArea, MyTextInput } from 'components/shared/form-inputs';
import { Form, Formik } from 'formik';
import {
  DELETE_COMMENT,
  DELETE_COMMENT_REPLY,
  POST_COMMENT,
  POST_COMMENT_REPLY
} from 'lib/mutations';
import { GET_REVIEW_COMMENTS_BY_COURSE } from 'lib/queries';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import styled from 'styled-components';
import toastr from 'toastr';
import main from 'utils/mutationWrapper';
import * as Yup from 'yup';

import { InvertedQuestionSubmitButton, QuestionSubmitButton } from '@/design-system/buttons';

const StyledQuestion = styled.div`
  // display: grid;
  // grid-template-columns: 3fr 1fr;
  // grid-gap: 20px;
  .section {
    height: 364px;
    border: 1px solid rgba(255, 255, 255, 0.8);
    box-sizing: border-box;
    border-radius: 8px;
  }
  .author {
    border-radius: 50%;
  }
  @media (max-width: 576px) {
    .section {
      margin: 0 10px;
    }
    .start-style,
    .question-style {
      font-size: 10px !important;
      padding-top: 10px !important;
    }
    .hide-mobile {
      display: none !important;
    }
  }

  .section-one {
    width: 266px;
    height: 360px;
    background: #262f40;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 8px;
  }
  .header-layout {
    display: flex;
    justify-content: start;
    align-items: center;
    height: 84px;
    background: #262f40;
  }

  .start-style {
    width: 170px;
    height: 40px;
  }

  .question-style {
    width: 140px;
    height: 40px;
  }
  .start-style,
  .question-style {
    border: 1px solid white;
    border-radius: 8px;
    margin-left: 24px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    padding-top: 4px;
  }

  .image-layout {
    margin-left: 24px;
    background: url(${(props) => props.img});
  }

  .title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
    padding: 16px 0 18px 16px;
  }

  .students-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px 8px;
    padding: 0 16px;
    .student-image-fit {
      // object-fit: contain;
    }
  }

  .textarea-style {
    width: 95%;
    height: 176px;
    background: #1a202b;
    border: none;
  }
  .text-area-style {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    min-height: 100px;
    margin: 0 24px 0 24px;
  }

  .btn-size {
    text-align: right;
    margin-right: 24px;
  }
  .btn-style {
    background: #e5e5e5;
    color: #000000;
  }
  .textarea-style:focus {
    outline: none;
  }
  .textarea-style::placeholder {
    color: #ffffff;
  }
  .comment-guest-notice {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    color: ${(props) => props.theme.primaryText};
    margin: 20px 0;
    text-align: center;
  }
`;

const StyleSection = styled.div`
  .section-card-one {
    margin: 24px 6px 0 62px;
    background: #262f40;
    border-radius: 4px;
    padding-bottom: 30px;
  }
  .author {
    border-radius: 50%;
  }
  .sub-section-card {
    display: flex;
  }
  .sub-section-card > div {
    margin: 24px 0 0 24px;
  }
  .author-name {
    margin-left: 16px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
  }
  .instructor {
    margin-left: 19px;
  }
  .author-container {
    margin: 4px 0 0 96px;
    display: flex;
    justify-content: space-between;
  }
  .author-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #ffffff;
  }
  .btns {
    display: flex;
  }
  .space-btn-one,
  .space-btn-two {
    margin-left: 16px;
  }
  .section-card-two {
    margin: 0 6px 0 155px;
    background: #262f40;
    border-radius: 4px;
    padding-bottom: 10px;
  }
  .section-card-three {
    margin: 2px 6px 0 155px;
    background: #262f40;
    border-radius: 4px;
  }
  .section-card-four {
    margin: 0 6px 0 155px;
    background: #262f40;
    border-radius: 4px;
    padding-bottom: 20px;
  }
  @media (max-width: 576px) {
    .section-card-one,
    .section-card-two,
    .section-card-three,
    .section-card-four {
      margin-left: 0;
    }
    .author-name {
      font-size: 12px;
    }
    .author-text {
      font-size: 14px;
    }
    .input-style {
      width: 100% !important;
    }
  }
  .input-style {
    width: 649px;
    height: 40px;
    background: #1a202b;
    border: none;
  }
  .input-style:focus {
    outline: none;
  }
  .input-style::placeholder {
    color: rgba(255, 255, 255, 0.8);
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    padding-left: 8px;
  }
  .btn-mobile {
    margin-right: 16px !important;
  }
  .name-section {
    display: flex;
    align-items: center;
  }
  .btn-reply {
    justify-content: flex-end;
  }
`;

const QuestionAndAnswer = ({ lesson, auth, paidCourse, activeSubscription }) => {
  const [cookies] = useCookies([]);
  const [comments, setComments] = useState([]);

  useEffect(() => {
    fetchCourseComments();
  }, []);

  const fetchCourseComments = async () => {
    const { findReviewCommentsByCourse: newCourseComments } = await main(
      GET_REVIEW_COMMENTS_BY_COURSE,
      {
        courseId: lesson.courseId
      },
      cookies
    );
    setComments(newCourseComments);
  };

  const handleQuestionSubmit = async (e, values, { resetForm }) => {
    e.preventDefault();
    if (!values.comment) {
      toastr.error('Please, add question');
      return;
    }
    let formData = {
      input: {
        comment: values.comment,
        courseId: lesson?.courseId
      }
    };

    try {
      await main(POST_COMMENT, formData, cookies);
      toastr.success('Your review was posted successfully');
      resetForm({});
      await fetchCourseComments();
    } catch (error) {
      console.log(error);
      toastr.error('Error posting question');
    }
  };

  const handleReplySubmit = async (e, values, commentId, { resetForm }) => {
    e.preventDefault();
    if (!values.reply) {
      toastr.error('Please, add reply');
      return;
    }
    let formData = {
      input: {
        reply: values.reply
      },
      commentId
    };

    try {
      await main(POST_COMMENT_REPLY, formData, cookies);
      toastr.success('Your reply was posted successfully');
      resetForm({});
      await fetchCourseComments();
    } catch (error) {
      console.log(error);
      toastr.error('Error posting reply');
    }
  };

  const handleCommentDelete = async (id) => {
    if (!id) {
      toastr.error('Please, specify question to delete');
      return;
    }

    try {
      await main(DELETE_COMMENT, { id }, cookies);
      toastr.success('Your question was deleted successfully');
      await fetchCourseComments();
    } catch (error) {
      console.log(error);
      toastr.error('Error deleting question');
    }
  };

  const handleReplyDelete = async (id) => {
    if (!id) {
      toastr.error('Please, specify reply to delete');
      return;
    }

    try {
      await main(DELETE_COMMENT_REPLY, { id }, cookies);
      toastr.success('Your reply was deleted successfully');
      await fetchCourseComments();
    } catch (error) {
      console.log(error);
      toastr.error('Error deleting reply');
    }
  };

  const userCanComment = (user, less) => {
    if (!user || !less) return false;
    if (user?._id === less?.author?._id) return true;
    if (activeSubscription || paidCourse) return true;
    return false;
  };

  return (
    <>
      <StyledQuestion>
        <Row>
          <Col lg={22} md={22} sm={24} xs={24} style={{ marginRight: '20px' }}>
            {!userCanComment(auth, lesson) ? (
              <div className="comment-guest-notice">
                You have to be a subscriber or buy the course to comment
              </div>
            ) : (
              <div className="section">
                <div className="header-layout">
                  <div className="image-layout">
                    {auth?.imageUrl && (
                      <Image
                        src={auth?.imageUrl}
                        className="author"
                        alt="author"
                        height="56"
                        width="56"
                      />
                    )}
                  </div>
                  <div className="start-style">Start Conversation</div>
                  <div className="question-style">Ask A Question</div>
                </div>
                <div>
                  <Formik
                    initialValues={{
                      comment: ''
                    }}
                    validationSchema={Yup.object({
                      comment: Yup.string().required('Comment is required')
                    })}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      await handleQuestionSubmit(values, { resetForm });
                      setSubmitting(false);
                    }}>
                    {({ values, resetForm, isSubmitting }) => (
                      <Form onSubmit={(e) => handleQuestionSubmit(e, values, { resetForm })}>
                        <div className="text-area-style">
                          <div>
                            <MyTextArea
                              label="Tell us more about your team"
                              name="comment"
                              id="comment"
                              placeholder="Ask A Question"
                              className="textarea-style"
                            />
                          </div>
                        </div>
                        <div className="btn-size">
                          <button type="submit" className="btn-style" disabled={isSubmitting}>
                            Send
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </StyledQuestion>

      <StyleSection>
        {comments?.map((comment) => (
          <Row key={comment?._id}>
            <Col lg={22} sm={24} xs={24}>
              <div className="section-card-one">
                <div className="sub-section-card">
                  <div className="name-section">
                    <span>
                      {comment?.user?.imageUrl && (
                        <Image
                          src={comment?.user?.imageUrl}
                          className="author"
                          alt="author"
                          height="56"
                          width="56"
                        />
                      )}
                    </span>
                    <span className="author-name">{`${comment?.user?.firstName} ${comment?.user?.lastName}`}</span>
                    {comment?.user?._id === lesson?.author?._id && (
                      <span className="instructor">
                        <QuestionSubmitButton small type="button">
                          Instructor
                        </QuestionSubmitButton>
                      </span>
                    )}
                  </div>
                </div>
                <div className="author-container">
                  <p className="author-text">{comment?.comment}</p>
                  <div className="btns btn-mobile">
                    {comment?.user?._id === auth?._id && (
                      <>
                        <InvertedQuestionSubmitButton
                          icon
                          title="Delete comment"
                          type="button"
                          onClick={() => handleCommentDelete(comment._id)}
                          className="space-btn-two">
                          <DeleteFilled />
                        </InvertedQuestionSubmitButton>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {comment?.replies?.map((reply) => (
                <div className="section-card-two">
                  <div className="sub-section-card">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span>
                        {reply?.user?.imageUrl && (
                          <Image
                            src={reply?.user?.imageUrl}
                            className="author"
                            alt="author"
                            height="56"
                            width="56"
                          />
                        )}
                      </span>
                      <span className="author-name">{`${reply?.user?.firstName} ${reply?.user?.lastName}`}</span>
                      {reply?.user?._id === lesson?.author?._id && (
                        <span className="instructor">
                          <QuestionSubmitButton small type="button">
                            Instructor
                          </QuestionSubmitButton>
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="author-container">
                    <p className="author-text">{reply?.reply}</p>
                    <div className="btns btn-mobile">
                      {comment?.user?._id === auth?._id && (
                        <>
                          <InvertedQuestionSubmitButton
                            icon
                            title="Delete reply"
                            onClick={() => handleReplyDelete(reply._id)}
                            type="button"
                            className="space-btn-two">
                            <DeleteFilled />
                          </InvertedQuestionSubmitButton>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))}
              {userCanComment(auth, lesson) && (
                <div className="section-card-two">
                  <Formik
                    initialValues={{
                      reply: ''
                    }}
                    validationSchema={Yup.object({
                      reply: Yup.string().required('Reply is required')
                    })}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      await handleReplySubmit(values, comment._id, { resetForm });
                      setSubmitting(false);
                    }}>
                    {({ values, resetForm, isSubmitting }) => (
                      <Form
                        onSubmit={(e) => handleReplySubmit(e, values, comment._id, { resetForm })}>
                        <div className="sub-section-card">
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span>
                              {auth?.imageUrl && (
                                <Image
                                  src={auth?.imageUrl}
                                  className="author"
                                  alt="author"
                                  height="56"
                                  width="56"
                                />
                              )}
                            </span>
                            <span className="author-name">
                              <MyTextInput
                                name="reply"
                                type="text"
                                placeholder="Leave a reply"
                                className="input-style"
                              />
                            </span>
                          </div>
                        </div>
                        <div className="btns btn-mobile btn-reply">
                          <>
                            <QuestionSubmitButton
                              small
                              disabled={isSubmitting}
                              type="submit"
                              className="space-btn-two">
                              Reply
                            </QuestionSubmitButton>
                          </>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              )}
            </Col>
          </Row>
        ))}
      </StyleSection>
    </>
  );
};

export default QuestionAndAnswer;
