import { Col, Row, Tabs } from 'antd';
import Comment from 'components/lesson/Comment';
import QuestionAndAnswer from 'components/lesson/QuestionAndAnswer';
import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';

const { TabPane } = Tabs;

export const StyledTabs = styled(Tabs)`
  &.no-mobile {
    @media (max-width: 550px) {
      display: none;
    }
  }
  &.no-tab {
    @media (min-width: 550px) and (max-width: 1024px) {
      display: none;
    }
  }
  width: 100%;
  padding: 12px;
  .content {
    color: #fff;
    line-height: 2;
    font-size: 1.5rem;
  }
  .content > h3,
  .tab-header {
    color: #fff;
    font-size: 2rem;
    font-weight: 600;
  }
  .ant-tabs-nav .ant-tabs-tab {
    font-size: 16px;
    color: ${(props) => props.theme.fadedGrey};
    :hover {
      color: ${(props) => props.theme.primaryText};
      opacity: 0.9;
    }
  }
  .ant-tabs-nav-list {
    margin-top: 10px;
    margin-bottom: 10 spx;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    font-size: 16px;
    color: ${(props) => props.theme.primaryText};
  }
  .ant-tabs-ink-bar {
    background-color: ${(props) => props.theme.primaryLink};
  }
  .ant-tabs-nav-scroll {
    display: flex;
    justify-content: center;
  }
  .ant-tabs-bar {
    margin: 0 0 40px;
    border: none;
    @media (min-width: 320px) and (max-width: 550px) {
      margin: 0 0 30px;
    }
  }
  .ant-tabs .ant-tabs-bottom-content.ant-tabs-content-animated,
  .ant-tabs .ant-tabs-top-content.ant-tabs-content-animated {
    padding: 0 40px;
    margin-bottom: 40px;
    @media (min-width: 320px) and (max-width: 800px) {
      margin-bottom: 30px;
      padding: 0 16px;
    }
    @media (min-width: 800px) and (max-width: 1024px) {
      padding: 0 35px;
    }
  }
  .ant-tabs-nav-container {
    padding-left: 40px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 32px;
    @media (min-width: 320px) and (max-width: 800px) {
      overflow-x: inherit;
      padding-left: 20px;
    }
  }
  .ant-tabs-nav.ant-tabs-nav-animated > div {
    @media (min-width: 320px) and (max-width: 840px) {
      width: 100vw;
    }
  }
  .ant-tabs-nav.ant-tabs-nav-animated > div > div.ant-tabs-tab:first-child {
    @media (min-width: 320px) and (max-width: 550px) {
      margin-left: 80px;
    }
    @media (min-width: 550px) and (max-width: 1024px) {
      margin-left: 120px;
    }
  }
  .ant-tabs-nav {
    border-bottom: none !important;
    @media (max-width: 550px) {
      display: grid;
    }
  }
  .tabs-extra-content {
    margin-top: 10px;
  }
  .ant-tabs-nav:before {
    border-bottom: none !important;
  }
  .ant-tabs-bottom > .ant-tabs-nav:before,
  .ant-tabs-bottom > div > .ant-tabs-nav:before,
  .ant-tabs-top > .ant-tabs-nav:before,
  .ant-tabs-top > div > .ant-tabs-nav:before {
    border-bottom: none;
  }
  .ant-tabs-tab-next-icon-target,
  .ant-tabs-tab-prev-icon-target {
    color: ${(props) => props.theme.primaryText};
    border-radius: 50%;
    padding: 3px;
    font-size: 12px;
  }
  div.ant-tabs-bar.ant-tabs-top-bar {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  .ant-tabs-extra-content {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-column-gap: 10px;
    margin-right: 10px;
  }
  .ant-tabs-nav-wrap {
    height: 50px;
    background: rgba(229, 214, 223, 0.1);
    border-radius: 4px;
    padding: 0 20px;
    align-items: center;
    margin-right: 10px;
    justify-content: center;
  }
  .ant-tabs-nav-list {
    margin: 0;
  }
  .ant-tabs-tab-btn {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    text-transform: capitalize;
    color: rgb(229, 214, 223);
    background: transparent;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    font-weight: 600;
    color: rgb(255, 255, 255);
    border-bottom: 6px solid rgb(172, 0, 93);
    font-size: 16px;
    line-height: 22px;
    .ant-tabs-tab-btn {
      color: rgb(255, 255, 255);
    }
  }
`;

const ReviewsAndDiscussion = ({
  lesson,
  auth,
  activeSubscription,
  tabBarExtraContent,
  reviews,
  hasAccess,
  paidCourse,
  isFree,
  course
}) => {
  return (
    <StyledTabs defaultActiveKey="1" tabBarExtraContent={tabBarExtraContent()}>
      <TabPane tab="About" key="about">
        <div className="content">
          <Row>
            <Col md={{ span: 16 }} sm={{ span: 24, offset: 0 }}>
              <h3 className="tab-header">{lesson?.name}</h3>
              <div>{lesson?.description}</div>
            </Col>
            <Col md={{ span: 8 }} xs={{ span: 24 }} sm={{ span: 24, offset: 0 }}>
              <div className="obj-author" style={{ background: '#262F40' }}>
                {course?.author && (
                  <Link href={`/instructors/${course?.author?.url}`} passHref>
                    <a>
                      <Image
                        width={120}
                        height={120}
                        className="obj-author-img"
                        src={course?.author?.imageUrl}
                        alt="Author"
                      />
                    </a>
                  </Link>
                )}

                <div className="obj-author-name">
                  <Link href={`/instructors/${course?.author?.url}`} passHref>
                    <a>
                      {course?.author?.firstName} {course?.author?.lastName}
                    </a>
                  </Link>
                </div>
                <div className="obj-author-bio">{course?.author?.bio}</div>
                <div className="obj-author-rate">
                  {/* TODO: @abiodun Calculate instructor rating */}
                  {/* <Rate disabled defaultValue={4.5} style={{ fontSize: '30px' }} allowHalf /> */}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </TabPane>
      <TabPane tab="Review" key="review">
        <div className="content">
          <h3>Ratings and Reviews</h3>
          <Comment
            auth={auth}
            activeSubscription={activeSubscription}
            lesson={lesson}
            reviews={reviews}
            hasAccess={hasAccess}
            paidCourse={paidCourse}
            isFree={isFree}
          />
        </div>
      </TabPane>
      <TabPane tab="Q&A" key="question">
        <div className="content">
          <QuestionAndAnswer
            lesson={lesson}
            auth={auth}
            activeSubscription={activeSubscription}
            paidCourse={paidCourse}
          />
        </div>
      </TabPane>
    </StyledTabs>
  );
};

export default ReviewsAndDiscussion;
