import { useField } from 'formik';
import styled from 'styled-components';

const StyledSelect = styled.select`
  width: 100%;
  height: 45px;
  background: ${(props) => props.theme.inputBg};
  box-shadow: -3px 3px 12px rgba(172, 0, 93, 0.12);
  border-radius: 5px;
  padding: 0 18px;
  border: none;
  margin-bottom: 15px;
`;

const StyledErrorMessage = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;
  color: ${(props) => props.theme.red};
  opacity: 0.6;
  margin-left: 15px;
`;

const StyledLabel = styled.label`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 15px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: ${(props) => props.theme.cardBackground};
`;

const StyledInput = styled.input`
  width: 100%;
  height: 45px;
  background: ${(props) => props.theme.inputBg};
  box-shadow: -3px 3px 12px rgba(172, 0, 93, 0.12);
  border-radius: 5px;
  padding: 14px 18px;
  border: none;
  margin-bottom: 15px;
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  min-height: 145px;
  height: auto;
  background: ${(props) => props.theme.inputBg};
  box-shadow: -3px 3px 12px rgba(172, 0, 93, 0.12);
  border-radius: 5px;
  padding: 14px 18px;
  border: none;
  margin-bottom: 15px;
`;

export const MySelect = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div>
      <StyledLabel htmlFor={props.id || props.name}>{label}</StyledLabel>
      <StyledSelect {...field} {...props} />
      {meta.touched && meta.error ? <StyledErrorMessage>{meta.error}</StyledErrorMessage> : null}
    </div>
  );
};

export const MyTextInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <div>
      <StyledLabel htmlFor={props.id || props.name}>{label}</StyledLabel>
      {meta.touched && meta.error ? <StyledErrorMessage>{meta.error}</StyledErrorMessage> : null}
      <StyledInput className="text-input" {...field} {...props} />
    </div>
  );
};

export const MyTextArea = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      <StyledLabel htmlFor={props.id || props.name}>{label}</StyledLabel>
      {meta.touched && meta.error ? <StyledErrorMessage>{meta.error}</StyledErrorMessage> : null}
      <StyledTextArea className="text-input" {...field} {...props} />
    </>
  );
};

export const MyCheckbox = ({ children, ...props }) => {
  // We need to tell useField what type of input this is
  // since React treats radios and checkboxes differently
  // than inputs/select/textarea.
  const [field, meta] = useField({ ...props, type: 'checkbox' });
  return (
    <>
      <label className="checkbox">
        <input type="checkbox" {...field} {...props} />
        {children}
      </label>
      {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
    </>
  );
};

export const MyRadio = ({ children, ...props }) => {
  // We need to tell useField what type of input this is
  // since React treats radios and checkboxes differently
  // than inputs/select/textarea.
  const [field, meta] = useField({ ...props, type: 'radio' });
  return (
    <>
      <label className="radio">
        <input type="radio" {...field} {...props} />
        {children}
      </label>
      {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
    </>
  );
};
